import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  /**
   * Spinner color
   * @default primary
   */
  @Input()
  color: 'primary' | 'gray' = 'primary';

  /**
   * Spinner size
   * @default medium
   */
  @Input()
  size: 'small' | 'medium' | 'large' = 'medium';

  /**
 * Spinner Thickness
 * @default medium
 */
  @Input()
  thickness: 'thin' | 'medium' | 'fat' = 'medium';

  /**
 * If Spinner Should be centered inside the container
 * @default false
 */
  @Input()
  isCentered = false;

  constructor() {}

  public get classes(): string[] {
    const classes = ['spinner', `spinner--${this.color}`, `spinner--${this.size}`, `spinner--thickness-${this.thickness}`];

    if(this.isCentered) {
      classes.push('spinner--centered');
    }
    return classes;
  }
}
