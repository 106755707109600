import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { MarkdownComponent, MARKED_OPTIONS, provideMarkdown } from 'ngx-markdown';

interface DetailStyle {
  [index: string]: string;
}

type AvailableTags = 'h1' | 'h2' | 'h3' | 'p' | 'a' | 'ul' | 'ol' | 'li';

export type MarkdownStyles = {
  [key in AvailableTags]?: string | DetailStyle;
};

@Component({
  selector: 'ui-md-text',
  standalone: true,
  templateUrl: './md-text.component.html',
  styleUrls: ['./md-text.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    //
    MarkdownComponent,
  ],
  providers: [
    // External library
    provideMarkdown({
      markedOptions: {
        provide: MARKED_OPTIONS,
        useValue: {
          gfm: true,
          breaks: true,
          pedantic: false,
          smartLists: true,
          smartypants: false,
        },
      },
    }),
  ],
})
export class MdTextComponent implements OnChanges {
  /**
   * Markdown classes
   * @optional
   */
  @Input()
  classname: string;

  /**
   * Content object with text to render and content type.
   * @required
   */
  @Input()
  markdownText: any;

  /**
   * Object with specific classes to be added to markdown components
   * @optional
   */
  @Input()
  styles?: MarkdownStyles;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private elementRef: ElementRef<HTMLElement>,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.['markdownText']?.currentValue) {
      setTimeout(() => {
        this.changeDetectorRef.detectChanges();
        this.addClassesToElements();
      }, 0);
    }
  }

  /**
   * Add classes to the elements specified on styles property
   */
  private addClassesToElements() {
    // TODO improve this code
    Object.keys(this.styles || {}).forEach((tag) => {
      // console.log('🚀 ~ MdTextComponent ~ Object.keys ~ tag:', tag);
      if (this.styles) {
        // Get elements by the tag specified
        const elements: Element[] = Array.from(this.elementRef.nativeElement.querySelectorAll(tag));
        // Get tag content
        let tagContent = this.styles[tag as AvailableTags];
        // If content is string, set the same classes for all elements
        if (tagContent && typeof tagContent === 'string') {
          this.addClassesToElementGroup(elements, tagContent);
          return;
        }
        // Set classes to individual elements
        tagContent = this.styles[tag as AvailableTags] as DetailStyle;
        if (tagContent && typeof tagContent === 'object') {
          // The elements on 0 are the general classes
          if (tagContent[0] && typeof tagContent[0] === 'string') {
            this.addClassesToElementGroup(elements, tagContent[0]);
            delete tagContent[0];
          }
          // Set classes to the rest elements
          Object.keys(tagContent).map((index: string) => {
            if (tagContent && typeof tagContent === 'object') {
              const classes = tagContent[index];
              if (classes && typeof classes === 'string') {
                this.addClassesToElement(elements[Number(index) - 1], tagContent[index]);
              }
            }
          });
        }
      }
    });
  }

  private addClassesToElement(element: Element, classes: string) {
    !!classes && element?.classList.add(...classes.split(' '));
  }

  private addClassesToElementGroup(elements: Element[], classes: string) {
    for (const element of elements) {
      this.addClassesToElement(element, classes);
    }
  }

  get classes() {
    return `markdown ${this.classname}`;
  }
}
