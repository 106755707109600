import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EMPTY_GUID } from '@fundo/app/constants/empty-guid';
import { AuthService } from '@fundo/app/services/auth.service';
import { environment } from '@fundo/environments/environment';
import { PageId } from '@shared/enums/page-id';
import { PIDenum } from '@shared/enums/pid-parameter-enum';
import { IdentityToken } from '@shared/models/identity-token.interface';
import { TrackingPixelRequest, TrackingPixelResponse } from '@shared/models/tracking-pixel';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { PixelActionId } from '../constants/pixel-action-id';

@Injectable()
export class PixelTrackingInjectorService {
  private readonly API_BASE = environment.apiBaseUrl;

  private actionPixels: Record<PixelActionId, string[]> = Object.values(PixelActionId).reduce((acc, id) => {
    acc[id] = [];
    return acc;
  }, {} as Record<PixelActionId, string[]>);

  private isSitePage: boolean;

  private ACTION_PIXELS_ARRAY = Object.keys(this.actionPixels);

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private readonly authService: AuthService,
    private httpClient: HttpClient,
  ) {}

  public injectTrackingPixels(idScreen: number, pid?: string) {
    this.getAuthToken()
      .pipe(
        mergeMap(() => {
          this.isSitePage = 
            [PageId.ConfirmationInfo, PageId.NonWorkflowRelated].includes(idScreen) && !!pid;

          if (this.isSitePage && pid) {
            return this.getSiteTrackingPixels(idScreen, pid);
          }
          return this.getLeadTrackingPixels(idScreen);
        }),
      )
      .subscribe((res) => {
        /**
         * Pixels on page load
         */
        const pagePixels = res
        .filter((el) => !this.ACTION_PIXELS_ARRAY.includes(el.description))

        /**
         * Pixels on action
         */
        this.ACTION_PIXELS_ARRAY.forEach(code => {
          this.actionPixels[code as PixelActionId] = res
            .filter(el => el.description === code)
            .map(res => res.trackingCode);
        });

        this.insertEachTrackingPixel(pagePixels);
      });
  }

  public injectPixelsForNonWorkflowPages() {
    this.injectTrackingPixels(PageId.NonWorkflowRelated, PIDenum.Organic);
  }

  private getAuthToken(): Observable<IdentityToken> {
    const publicId = this.authService.getPublicId();
    if (!publicId) this.authService.setPublicId(EMPTY_GUID);

    return this.authService.getAuthorizationToken();
  }

  private getLeadTrackingPixels(idScreen: number): Observable<TrackingPixelResponse[]> {
    return this.httpClient.get<TrackingPixelResponse[]>(`${this.API_BASE}/trackingPixel/lead`, {
      params: { idScreen },
    });
  }

  private getSiteTrackingPixels(
    idScreen: number,
    pid: number | string,
  ): Observable<TrackingPixelResponse[]> {
    return this.httpClient.get<TrackingPixelResponse[]>(`${this.API_BASE}/trackingPixel/site`, {
      params: { idScreen, pid },
    });
  }

  private postInjectedPixelConfirmation(payload: TrackingPixelRequest): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this.API_BASE}/trackingPixel/lead`, payload);
  }

  private insertEachTrackingPixel(pixels: TrackingPixelResponse[] | string[]) {
    const pixelContainer =
      this.document.getElementById('tracking-pixel-container') || ({} as HTMLElement);

    this.deleteChild(pixelContainer);

    pixels.forEach((pixel) => {
      const trackingCode = typeof pixel === 'string' ? pixel : pixel.trackingCode;
      const node = document.createRange().createContextualFragment(trackingCode);
      pixelContainer.appendChild(node);

      // Confirm lead generation pixel injection:
      if(!this.isSitePage && typeof pixel === 'object' && pixel.id) {
        const payload: TrackingPixelRequest = { trackingPixelId: pixel.id };
        this.postInjectedPixelConfirmation(payload).subscribe();
      }
    });
  }

  injectActionTrackingPixels(pixelIds: PixelActionId[]) {
    pixelIds.forEach(id => this.insertActionPixels(id));
  }

  /**
   * Insert remining pixels
   */
  insertActionPixels(action: PixelActionId) {
    this.insertEachTrackingPixel(this.actionPixels[action] || []);
  }

  private deleteChild(element: HTMLElement) {
    element.innerHTML = '';
  }
}
