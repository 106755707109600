export enum DENIED_REASON_RULES {
  IncomeSource,
  BankAccount,
  State,
  Financial,
}

export interface DenialReasonWrapper {
  denialReason: string;
}

const DEFAULT_DENIED_REASON =
  'Based on our review of your application, we are unable to move forward with your request for business funding at this time.';

/**
 * Financial and non financial denied reasons messages and its corresponding equivalents
 */
const DENIED_REASONS_MAPPING: {
  reference: RegExp;
  deniedRule: DENIED_REASON_RULES;
  message: string;
}[] = [
  {
    reference: /^Not qualified due to income source$/g,
    deniedRule: DENIED_REASON_RULES.IncomeSource,
    message:
      'Unfortunately, we are unable to approve your application for a Merchant Cash Advance at this time, as we exclusively provide funds to be used for business purposes. We will withdraw your application to ensure you no longer receive emails and text messages.',
  },
  {
    reference: /^Customer's Bank Accounts Rule:/g,
    deniedRule: DENIED_REASON_RULES.BankAccount,
    message: 'We are unable to process the bank account you specified.',
  },
  {
    reference: /^State Rule:/g,
    deniedRule: DENIED_REASON_RULES.State,
    message:
      'At this time we are unable to process your application as we do not do business in your state.',
  },
  {
    reference: /^Offer amount is less than minimum allowed$/g,
    deniedRule: DENIED_REASON_RULES.Financial,
    message: '',
  },
  {
    reference: /^Score value is less then minimum allowed$/g,
    deniedRule: DENIED_REASON_RULES.Financial,
    message: '',
  },
  {
    reference: /^No accounts are approved$/g,
    deniedRule: DENIED_REASON_RULES.Financial,
    message: '',
  },
  {
    reference: /^Number of Transaction is less then minimum allowed$/g,
    deniedRule: DENIED_REASON_RULES.Financial,
    message: '',
  },
  {
    reference: /^Total Remit value is higher than maximum allowed$/g,
    deniedRule: DENIED_REASON_RULES.Financial,
    message: '',
  },
  {
    reference: /^AMR value is less than minimum allowed$/g,
    deniedRule: DENIED_REASON_RULES.Financial,
    message: '',
  },
];

export class DeniedManager {
  constructor() {}

  /**
   * Whether an application is NonFinancial type given a denialReason
   * @param denialReason
   * @returns
   */
  isNonFinancialDeniedApplication(denialReason: string): boolean {
    const rule = DENIED_REASONS_MAPPING.find((element) => denialReason.match(element.reference))
      ?.deniedRule;
    return (
      !!rule &&
      [
        DENIED_REASON_RULES.IncomeSource,
        DENIED_REASON_RULES.BankAccount,
        DENIED_REASON_RULES.State,
      ].includes(rule)
    );
  }

  /**
   * Whether an application is Financial type given a denialReason
   * @param denialReason
   * @returns
   */
  isFinancialDeniedApplication(denialReason: string): boolean {
    return (
      DENIED_REASONS_MAPPING.find((element) => denialReason.match(element.reference))
        ?.deniedRule === DENIED_REASON_RULES.Financial
    );
  }

  /**
   * Get the rule associated to the denialReason provided
   * @param denialReason
   * @returns
   */
  isDeniedByIncomeSourceRule(denialReason: string): boolean {
    return (
      DENIED_REASONS_MAPPING.find((element) => denialReason.match(element.reference))
        ?.deniedRule === DENIED_REASON_RULES.IncomeSource
    );
  }

  /**
   * Get the message associated to the denialReason provided
   * @param denialReason
   * @returns the associated message or default message
   */
  getDenialReasonAssociatedMessage(denialReason: string): string {
    return (
      DENIED_REASONS_MAPPING.find((element) => denialReason.match(element.reference))?.message ||
      DEFAULT_DENIED_REASON
    );
  }
}
