import { Environment, PLAID_SETTINGS } from './environment.model';

export const environment: Environment = {
  production: false,
  showCaptcha: true,
  apiBaseUrl: 'https://app-api-qa.fundo.com/api/v1',
  plaidSettings: {
    plaidPublicKey: PLAID_SETTINGS.plaidPublicKey,
    env: 'sandbox',
    clientName: PLAID_SETTINGS.clientName,
    product: PLAID_SETTINGS.product,
  },
  captchaKey: '6Lf5MsMZAAAAAE5JqQ9SXWr8jximjOr7L4cCEW1T',
  googleId: 'UA-157340971-1',
  segmentApiKey: 'aPw1G7RKMjBq7z58cpyPuuGxARMtTt5A',
  pid_test: 9898,
  main_page: 'https://fundo.com',
};
