export enum PageId {
  HomePage = 100,
  ConfirmationInfo = 1,
  ApplyFormDetails = 2,
  IncomeInfo = 21,
  PersonalDetails = 22,
  BankVerification = 23,
  BankAccountConfirmation = 24,
  PlaidReportPreparation = 3,
  OfferPage = 4,
  Programs = 5,
  Agreement = 6,
  References = 7,
  Documents = 8,
  AppComplete = 9,
  ManualReview = 80,
  ApplyformDenied = 91,
  ApplyFormDeniedFinancial = 92,
  NonWorkflowRelated = 99,
}

export function getPageIdName(pageId: PageId): string {
  const nameMap: { [key: number]: string } = {
    [PageId.ConfirmationInfo]: 'Confirmation Info',
    [PageId.ApplyFormDetails]: 'Apply Form Details',
    [PageId.IncomeInfo]: 'Income Info',
    [PageId.PersonalDetails]: 'Personal Info',
    [PageId.BankVerification]: 'Bank Verification Info',
    [PageId.BankAccountConfirmation]: 'Bank Account Confirmation',
    [PageId.PlaidReportPreparation]: 'Plaid Report Preparation',
    [PageId.OfferPage]: 'Offer Page',
    [PageId.Programs]: 'Programs',
    [PageId.Agreement]: 'Agreement',
    [PageId.References]: 'References',
    [PageId.Documents]: 'Documents',
    [PageId.AppComplete]: 'App Complete',
    [PageId.ManualReview]: 'Manual Review',
    [PageId.ApplyformDenied]: 'Apply Form Denied',
    [PageId.ApplyFormDeniedFinancial]: 'Apply Form Denied Financial',
    [PageId.NonWorkflowRelated]: 'Non Workflow Related',
  };
  return nameMap[pageId as number] as string || '';
}