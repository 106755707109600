import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, afterNextRender } from '@angular/core';
import { environment } from '@fundo/environments/environment';
import { IdentityToken } from '@shared/models/identity-token.interface';
import { NavigationService } from '@shared/services/navigation.service';
import { Observable, Subject } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { EMPTY_GUID } from '../constants/empty-guid';

export enum IDENTITY {
  Token = 'IDENTITY_TOKEN',
  PublicId = 'PUBLIC_ID',
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly API_BASE = environment.apiBaseUrl;

  private userLoggedIn = new Subject<boolean>();
  token: any;

  constructor(
    private httpClient: HttpClient,
    private navigationService: NavigationService,
  ) {
    this.userLoggedIn.next(false);
  }

  public getToken(): string {
    const item = localStorage.getItem(IDENTITY.Token);
    if (item && item !== '') {
      return JSON.parse(item).accessToken;
    }
    return '';
  }

  public refreshToken(): Observable<IdentityToken> {
    const params = { refreshToken: this.getRefreshToken() };

    return this.httpClient
      .get<IdentityToken>(`${this.API_BASE}/identity/usertoken/refresh`, {
        params,
      })
      .pipe(
        take(1),
        tap((token) => {
          this.setToken(token);
          return token;
        }),
      );
  }

  public logOut(): void {
    localStorage.clear();
    this.navigationService.navigateToLoginPage();
  }

  public getRefreshToken(): string {
    const item = localStorage.getItem(IDENTITY.Token);
    if (item && item !== '') {
      return JSON.parse(item).refreshToken;
    }
    return '';
  }

  public setToken(token: IdentityToken): void {
    this.saveToStorage(token, IDENTITY.Token);
  }

  private saveToStorage<T>(data: T, key: IDENTITY): void {
    localStorage.setItem(key, JSON.stringify(data));
  }

  public setPublicId(publicId: string): void {
    this.clearCurrentSession();
    localStorage.setItem(IDENTITY.PublicId, publicId);
  }

  public getPublicId(): string {
    return localStorage.getItem(IDENTITY.PublicId) || '';
  }

  public getAuthorizationToken(): Observable<IdentityToken> {
    const publicId: string = this.getPublicId() || EMPTY_GUID;

    return this.httpClient
      .get<IdentityToken>(`${this.API_BASE}/identity/token?publicId=${publicId}`)
      .pipe(
        tap((token) => {
          this.setToken(token);
          return token;
        }),
      );
  }

  clearCurrentSession(): void {
    localStorage.removeItem(IDENTITY.Token);
    localStorage.removeItem(IDENTITY.PublicId);
  }
}
