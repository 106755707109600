import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { DenialReasonWrapper, DeniedManager } from '@fundo/app/classes/denied-manager.class';
import { OfferCalculationResponse } from '@fundo/app/models/offer-calculation-response';
import { AuthService } from '@fundo/app/services/auth.service';
import { environment } from '@fundo/environments/environment';
import { PIDenum } from '@shared/enums/pid-parameter-enum';

import { LeadStatusNumber } from '../enums/lead-status';

// TODO
// import { LeadStatusRequest } from '@fund/app/app-form-page/components/app-form-stepper/models/lead-status';
// import { OfferCalculationResponse } from '@fundo/app/offer-page/components/offer-page/models/offer-calculation-response';

export enum FoPageRoute {
  Main = '/',
  AppForm = '/app-form',
  AppForm2 = '/app-form-2',
  AppFormNewLead = '/new-lead',
  ApplyForm = '/apply-form',
  ApplyFormDetails = '/apply-form/details',
  ApplyFormDenied = '/apply-form/denied',
  ApplyFormDeniedFinancial = '/apply-form/denied/financial',
  ApplyNowForm = '/apply-now',
  ApplyNowFormDetails = '/apply-now/details',
  CustomerInfo = '/customer-info',
  AdditionalInfo = '/additional-info',
  ApplyNowFormDenied = '/apply-now/denied',
  ApplyNowFormDeniedFinancial = '/apply-now/denied/financial',
  PlaidReportPreparationPage = '/plaid-report-preparation',
  OfferPage = '/offer-page',
  DeniedPage = '/app-denied',
  DeniedPageFinancial = '/app-denied-financial',
  ErrorPage = '/general-error',
  ManualReviewPage = '/manual-review',
  NewForm = '/new-form',
  Advances = '/advances',
  Login = '',
  Unsubscribe = '/unsubscribe',
  Programs = '/programs',
  Agreement = '/agreement',
  References = '/references',
  Documents = '/documents',
  CompletePage = '/app-complete',
  NotAuthorized = '/not-authorized',
  AccountConfirmation = '/account-confirmation',
  RevenueVerification = '/revenue-verification',
  WrongIp = '/wrongip',
}

export type RedirectToDataType = any;

/*

TODO
  LeadStatusRequest |
  OfferCalculationResponse;
*/

@Injectable()
export class NavigationService {
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
  ) {}

  public navigateToMainPage(): void {
    // this.navigate(FoPageRoute.Main);
    this.authService.clearCurrentSession();
    document.location.href = environment.main_page;
  }

  //======================
  // Apply-form
  // =====================

  public navigateToAppDeniedPage(
    data?: OfferCalculationResponse | DenialReasonWrapper,
    isOfferCalculationResponse = false,
  ): void {
    const deniedManager = new DeniedManager();
    // Is OfferCalculationResponse or match the specific denied reason
    if (
      isOfferCalculationResponse ||
      (!!data &&
        'denialReason' in data &&
        deniedManager.isFinancialDeniedApplication(data.denialReason))
    ) {
      this.navigate(FoPageRoute.DeniedPageFinancial, data);
      return;
    }

    // By default navigate to denied page
    this.navigate(FoPageRoute.DeniedPage);
  }

  // ====================
  // Apply-now-form (NEW)
  // ====================

  public navigateToApplyNowForm(data?: RedirectToDataType, params?: Params): void {
    if(!params) {
      this.router.navigateByUrl(FoPageRoute.ApplyNowForm, { state: data });
      return;
    }
    this.router.navigate([FoPageRoute.ApplyNowForm], { queryParams: params });
  }

  public navigateToApplyNowFormOrganic(): void {
    this.router.navigate([FoPageRoute.ApplyNowForm], {
      queryParams: { PID: PIDenum.Organic },
    });
  }

  // Stepper Confirmation Info (income - personal - bank)
  public navigateToCustomerInfo(data?: RedirectToDataType): void {
    this.navigate(FoPageRoute.CustomerInfo, data);
  }
  // Stepper Additional Info (agreement - documents - references)
  public navigateToAdditionalInfo(data?: RedirectToDataType): void {
    this.navigate(FoPageRoute.AdditionalInfo, data);
  }

  // ======================

  public navigateToAgreementPage(status?: RedirectToDataType): void {
    this.navigate(FoPageRoute.Agreement, status);
  }

  public navigateToLoginPage(): void {
    this.navigate(FoPageRoute.Login);
  }

  public navigateToPlaidReportPreparationPage(data?: RedirectToDataType): void {
    this.navigate(FoPageRoute.PlaidReportPreparationPage, data);
  }

  public navigateToAccountConfirmation(data?: RedirectToDataType): void {
    this.navigate(FoPageRoute.AccountConfirmation, data);
  }

  public navigateToRevenueVerification(): void {
    this.navigate(FoPageRoute.RevenueVerification);
  }

  public navigateToOfferPage(data?: RedirectToDataType): void {
    this.navigate(FoPageRoute.OfferPage, data);
  }

  public navigateToErrorPage(): void {
    this.navigate(FoPageRoute.ErrorPage);
  }

  public navigateToManualReviewPage(): void {
    this.navigate(FoPageRoute.ManualReviewPage);
  }

  public navigateToUnsubscribePage(): void {
    this.navigate(FoPageRoute.Unsubscribe);
  }

  public navigateToProgramsPage(data?: RedirectToDataType): void {
    this.navigate(FoPageRoute.Programs, data);
  }

  public navigateToCompletePage(): void {
    this.navigate(FoPageRoute.CompletePage);
  }

  navigateToWrongIpPage(): void {
    this.navigate(FoPageRoute.WrongIp);
  }

  private navigate(path: string, data?: RedirectToDataType): void {
    if (data) {
      this.router.navigateByUrl(path, { state: { data } });
    } else {
      this.router.navigateByUrl(path);
    }
  }
}
